* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  scroll-padding-top: 100px;
  -webkit-user-drag: none;
}
body {
  font-family: "DM Sans", sans-serif;
}
:root {
  --blue: #1666ff;
  --yellow: #ffda79;
  --green: #c9fa75;
  --greyp: #535766;
  --white: #ffffff;
  --black: #0b0b0b;
  --medium: 16px;
  --large: 24px;
}
a {
  text-decoration: none;
}
/* a:hover{
color: #9076fc;
} */

.purpletxt {
  color: #9076fc;
}
.skytxt {
  color: #61cfeb;
}

.yellowbtn {
  background-color: var(--yellow);
  color: #000;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 16px;
  border: none;
  border: 1px solid var(--yellow);
  border-radius: 10px;
}
.yellowbtn:hover {
  transition: 0.3s ease-in;
  color: #000;
  background-color: #ffcb4a;
  /* background-color: transparent; */
  border: 1px solid #ffcb4a;
}
.greenbtn {
  border: 1px solid var(--green);
  background-color: transparent;
  border: 1px solid var(--green);
  color: var(--green);
  font-size: 16px;
  font-weight: 600;
  padding: 10px 16px;
  border-radius: 10px;
}
.greenbtn:hover {
  background-color: var(--green);
  color: var(--black);
  transition: 0.3s ease-in;
}
.blackbtn {
  background-color: var(--black);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 16px;
  border: none;
  border: 1px solid var(--black);
  border-radius: 10px;
}
.blackbtn:hover {
  transition: 0.3s ease-in;
  color: var(--black);
  background-color: transparent;
  border: 1px solid var(--black);
}
.whitelinebtn {
  background-color: transparent;
  color: #0072fa;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 40px;
  border-radius: 12px;
  background: #fff;
  border: 1px solid #ffff;
}

/* NAV */
.navbar-light .navbar-toggler {
  border-color: #fff;
}
.self-nav.navbar-light .navbar-toggler-icon {
  background-image: url(./assets/Images/menuIcon.png);
}
.nav-bg {
  padding: 8px 0;
  background: var(--black);
}
.navbar-light .navbar-nav .nav-link {
  color: #d8d8d899;
  font-size: 16px;
  cursor: pointer !important;
  font-weight: 400;
}
.navbar-light .navbar-nav .nav-item.active .nav-link {
  color: #fff;
  font-weight: 600;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
  font-weight: 600;
}
.self-nav .navbar-brand img {
  width: 225px;
}
.self-nav {
  padding: 12px 0px;
}
.self-nav ul.navbar-nav li a {
  margin: 0 15px;
}
.self-nav ul.navbar-nav li a.active {
  color: #0072fa !important;
  font-weight: 600;
  /* border-radius: 0px 0px 5px 5px; */
  border-bottom: 2px solid #2863d5;
}
.navbtn {
  position: absolute;
  right: 0;
}

/* HEROSEC */
.blackbg {
  background-color: var(--black);
}
.hero-sec {
  padding: 10px 0 50px;
  background-color: var(--black);
}
.hero-sec h1 {
  color: #fff;
  font-size: 42px;
  letter-spacing: 2px;
  font-weight: 600;
}
.hero-sec h1 span {
  color: var(--yellow);
}
.hero-sec h4 span {
  padding: 5px 18px;
  border-radius: 50px;
  background-color: var(--yellow);
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.hero-sec p {
  margin-bottom: 20px;
  color: #b5b5b5;
  font-size: 16px;
  font-weight: 400;
}
.heroimg img {
  filter: drop-shadow(4px 19px 16px rgba(128, 128, 128, 0.397));
  width: 680px;
}
.twobutton {
  display: flex;
  gap: 10px;
}

/* OTHERSEC */
.pagepadding {
  /* padding: 50px 0; */
}
.productsec {
  padding: 50px 30px;
  background: #f0f0f0;
}
.product-text {
  padding: 0 0 28px;
}
.product-text h4 {
  margin-bottom: 0;
  color: var(--black);
  font-size: 38px;
  font-weight: 700;
}
.product-text h4 span {
  color: #0072fa;
  font-weight: 700;
}
.product-text p {
  color: var(--greyp);
  font-size: 16px;
}
.itembg {
  border-radius: 20px;
  padding: 30px;
  background-color: #eaf5fb;
}
.product-items {
  height: 100%;
  max-height: 100%;
  border-radius: 20px;
  background: #f7f7f9;
  padding: 30px;
}
.product-items img {
  width: 100%;
}
.product-items h5 {
  margin-bottom: 0px;
  color: var(--black);
  font-size: 28px;
  font-weight: 700;
}
.product-items p {
  margin: 12px 0 16px;
  color: var(--greyp);
  font-size: 16px;
  font-weight: 400;
}
.keybox-sec h6 {
  margin-bottom: 0;
  color: var(--black);
  font-size: 36px;
  font-weight: 700;
}
.keybox-sec p {
  color: var(--greyp);
  font-size: 16px;
  font-weight: 400;
}

.keybox-img img {
  filter: drop-shadow(3px 4px 25px rgba(128, 128, 128, 0.397));
}
.keybox-sec img {
  width: 160px;
}
.feature-sec {
  padding: 100px 0;
}

/* BGSETP */
.logobox {
  background-color: var(--white);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  padding: 24px;
  height: 100%;
  max-height: 100%;
}
.serviec-spacing {
  padding: 10px;
}
.serive-items {
  height: 100%;
  max-height: 100%;
  border-radius: 12px;
  background: var(--black);
  padding: 40px;
}
.serive-items h5 {
  margin: 25px 0 15px;
  color: var(--white);
  font-size: 28px;
  font-weight: 600;
}
.serive-items p {
  color: #b5b5b5;
  font-size: 16px;
  font-weight: 400;
}
.take-items {
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #e8e8ea;
}
.take-items h5 {
  margin: 20px 0 12px;
}
.take-items h5 span {
  padding: 6px 12px;
  border-radius: 6px;
  background-color: var(--black);
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
}
.take-items p {
  color: var(--black);
  font-size: 20px;
  font-weight: 500;
}
.takeicon img {
  width: 100%;
}
.clientmain {
  display: flex;
  align-items: center;
  gap: 10px;
}
.clientmain p {
  margin-bottom: 0;
  color: #535766;
  font-size: 16px;
  font-weight: 500;
}
.clientmain span {
  color: #535766;
  font-size: 16px;
  font-weight: 400;
}
.trustsec {
  padding: 50px 0;
  background-color: var(--black);
}
.trustsec .product-text h4 {
  color: var(--white);
}
.trust-items {
  position: relative;
  padding: 30px 16px;
  background-color: var(--white);
  border-radius: 6px;
}
.trsuz {
  z-index: 1;
  position: relative;
}
.trust-items::before {
  content: "";
  position: absolute;
  border-radius: 6px;
  width: 100%;
  height: 40%;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--yellow);
  clip-path: polygon(100% 0%, 100% 78%, 50% 95%, 50% 96%, 0 78%, 0 0);
}
.trust-items h4 {
  color: var(--black);
  font-weight: 800;
  font-size: 14px;
}
.trust-items h6 {
  color: var(--greyp);
  font-weight: 400;
  font-size: 12px;
}
.trust-items p {
  margin: 14px 0;
  color: var(--black);
  font-weight: 400;
  font-size: 14px;
}
.trymain {
  padding: 150px 0;
}
.letsmain {
  position: relative;
}
.letsimg img {
  width: 700px;
}
.letsmain .letsimg {
  position: absolute;
  /* right: 0; */
  bottom: -2px;
}

/* Accordina */
.accordsec {
  padding: 100px 0;
}
.accordsec .product-text p {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
}
.selfaccord .accordion-item {
  border-radius: 16px;
  margin-bottom: 10px;
  border: 1px solid #e1e1e1;
}
.selfaccord .accordion-item:first-child {
  border-top: 1px solid #e1e1e1;
}
.selfaccord .accordion-item {
  padding: 10px;
  background: transparent;
}
.selfaccord .accordion-button {
  padding: 10px;
  color: var(--black);
  background: transparent;
  font-size: 16px;
  font-weight: 500;
}
.selfaccord .accordion-button:not(.collapsed) {
  color: var(--black);
  box-shadow: none;
}
.selfaccord .accordion-button:focus {
  border-color: none;
  box-shadow: none;
}
.accordion-flush .accordion-item:last-child {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.road-heading h5 {
  color: var(--black);
  font-size: 40px;
  font-weight: 700;
}
.road-heading p {
  margin-bottom: 0;
  color: var(--greyp);
  font-size: 14px;
  font-weight: 400;
}

.client-detail {
  display: flex;
  align-items: center;
}
.client-detail h5 {
  font-weight: 700;
  margin-bottom: 0;
  color: #0b081d;
  font-size: 22px;
}
.client-detail p {
  margin: 0;
  color: #4f46e5;
  font-size: 18px;
}
.client-detail img {
  margin-right: 20px;
  width: 80px;
  border-radius: 50%;
}

.bgaboveimg {
  background: var(--green);
}

/* Footer */

footer {
  background-color: var(--black);
  color: var(--white);
}
.footer {
  padding: 60px 0;
}
.link-detail img {
  width: 225px;
}
.link-detail h6 {
  margin-bottom: 18px;
  color: #dee2ee;
  font-size: 18px;
  font-weight: 700;
}
.link-detail p {
  margin-bottom: 10px;
}
.link-detail p a {
  text-decoration: none;
  color: #dee2ee;
  font-size: 16px;
  font-weight: 400;
}
.link-detail p a:hover {
  color: var(--yellow);
}
.subscribe-detail h6 {
  margin-bottom: 10px;
  color: #fd7e14;
  font-size: 16px;
  font-weight: 500;
}
.subscribe-detailp {
  margin-bottom: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.icon-sec {
  display: flex;
  align-items: center;
}
.icon-sec span {
  width: 35px;
  height: 35px;
  padding: 25px;
  margin-right: 12px;
  border-radius: 50%;
  background-color: #9076fc26;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.icon-sec span img {
  width: 30px;
}
.contact {
  display: flex;
  align-items: center;
}
.contact p {
  margin-bottom: 0;
  color: #dee2ee;
  font-weight: 700;
}

.contact i {
  margin-right: 10px;
  color: var(--yellow);
}
.contact:hover {
  color: var(--yellow);
}
.copy-right {
  padding: 10px 0;
  border-top: 1px solid #0b081d41;
  display: flex;
  align-items: center;
  justify-content: center;
}
.copy-right p {
  margin-bottom: 0;
}
.disctext {
  display: flex;
  align-items: center;
  gap: 10px;
}
.disctext h4 {
  color: #0b081d;
  font-size: 20px;
  font-weight: 700;
}
.disctext img {
  width: 150px;
}

.contactbtn {
  padding: 10px 0;
}
/* Contact */
.formsec {
  padding: 25px;
  border-radius: 20px;
  background-color: #fff;
}
.haveaque {
  display: grid;
  padding: 25px;
  border-radius: 20px;
  height: 100%;
  background-color: #9076fc;
}
.haveaque h5 {
  font-weight: 700;
  font-size: 26px;
  color: #fff;
}
.haveaque p {
  font-size: 18px;
  color: #fff;
}
.inputsec {
  margin-bottom: 20px;
}
.inputsec label {
  margin-bottom: 8px;
  color: #0b081d;
  font-weight: 700;
}
.inputsec .form-control {
  padding: 10px;
}
.contact-trust {
  margin-bottom: 100px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 10px 50px 0px
    rgba(61.999999999999716, 54.99999999999995, 177.99999999999994, 0.12);
}
.contact-sourcemain {
  padding: 80px 0 0;
}
.contact-source {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-source span i {
  font-size: 30px;
  margin-right: 12px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.purplevarient i {
  color: #9076fc;
  background-color: #9076fc26;
}
.bluevarient i {
  color: #0b081d;
  background-color: #0b081d4d;
}
.skyvarient i {
  color: #61cfeb;
  background-color: #61cfeb45;
}
.contact-source h5 {
  font-weight: 700;
  color: #0b081d;
}
.contact-source p {
  margin-bottom: 0;
  font-weight: 700;
  color: #4f46e5;
}

/* OUR WORK */
.connectimg img {
  border-radius: 15px;
}
.connectimg-detail h5 {
  font-weight: 700;
  font-size: 30px;
  color: #0b081d;
}
.connectimg-detail p {
  font-size: 16px;
  color: #0b081d;
}
.lining-bg {
  background-image: url(./assets/Images/lining-bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.connect-move {
  margin-top: 50px;
  display: flex;
  align-items: center;
}
.connect-move h4 {
  font-weight: 700;
  font-size: 36px;
  color: #0b081d;
}
.connect-move p {
  font-size: 18px;
  color: #0b081d;
}

/* About */
.westrivemain {
  padding: 30px 0;
}
.westrive-img img {
  width: 400px;
}
.westrive h5 {
  font-weight: 700;
  font-size: 36px;
  color: #0b081d;
}
.westrive p {
  font-size: 16px;
  color: #0b081d;
}
.misiontxt h4 {
  font-weight: 600;
  font-size: 24px;
  color: #0b081d;
}
.misiontxt p {
  font-size: 16px;
  color: #0b081d;
}
.nudetail-main {
  margin: 40px 0;
  text-align: center;
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 10px 50px 0px
    rgba(61.999999999999716, 54.99999999999995, 177.99999999999994, 0.25);
}
.nudetail-sec h4 {
  color: #61cfeb;
  font-size: 48px;
  font-weight: 600;
}
.nudetail-sec h4 {
  color: #61cfeb;
  font-size: 40px;
  font-weight: 600;
}
.nudetail-sec p {
  font-size: 20px;
  font-weight: 600;
  color: #0b081d;
}

.valueimg img {
  box-shadow: 0px 10px 40px 0px
    rgba(61.999999999999815, 54.99999999999995, 177.99999999999994, 0.25);
  border-radius: 10px;
}

/* Media */

/* 1680 */
@media (max-width: 1700px) {
}

/* 1500 */
@media (max-width: 1680px) {
}

/* 1440 */
@media (max-width: 1449px) {
  .heroimg img {
    width: 500px;
  }
}

/* 1280 */
@media (max-width: 1439px) {
  .self-nav ul.navbar-nav li a {
    margin: 0 15px;
  }
  .hero-sec h1 {
    margin-bottom: 20px;
    font-size: 34px;
  }
  .trus-img img {
    width: 150px;
  }
  .product-items h5 {
    font-size: 22px;
  }
  .product-items p {
    font-size: 16px;
  }
  .road-heading h5 {
    font-size: 32px;
  }
  .road-heading p {
    font-size: 16px;
  }
  .percentage h6 {
    font-size: 36px;
  }
  .percentage p {
    font-size: 20px;
  }
  .thirdposition {
    margin-top: 151px;
  }
  .secposition {
    margin-top: -100px;
  }
  .trymain {
    padding: 140px 0;
  }
}

/* 992 */
@media (max-width: 1279px) {
  .heroimg img {
    width: 495px;
  }

  .nav-bg {
    padding: 0px 0;
  }
  .trustsec {
    padding: 50px 0;
  }
  .self-nav ul.navbar-nav li a {
    margin: 0 5px;
  }
  .product-text h4 {
    font-size: 28px;
  }
  .roadmain {
    padding: 70px 0;
  }
  .keybox-sec p {
    font-size: 16px;
  }
  .westrive h5 {
    font-size: 24px;
  }
  .misiontxt h4 {
    font-size: 20px;
  }
  .connect-move h4 {
    font-size: 30px;
  }
  .connectimg-detail h5 {
    font-size: 24px;
  }
  .haveaque h5 {
    font-size: 22px;
  }
  .haveaque p {
    font-size: 16px;
  }
  /* .contact-sourcemain {
    padding: 50px 0;
  } */
  .lineimg {
    top: 118px;
    bottom: unset;
  }
  .thirdposition {
    margin-top: 200px;
  }
  .accordsec {
    padding: 60px 0;
  }
}

/* 768 */
@media (max-width: 991px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 8px 30px 8px 0;
  }
  .navbar-nav {
    margin-top: 20px;
  }
  .navbtn {
    position: unset;
  }
  .self-nav .navbar-nav .nav-item a {
    display: inline-block;
  }
  .hero-sec p {
    font-size: 18px;
  }
  .roadmain {
    padding: 50px 0;
  }
  .roadmap-img img {
    width: 500px;
  }
  .copy-right {
    flex-direction: column;
  }
  /* .copy-right p {
    margin-bottom: 16px;
  } */
  .footer {
    padding: 24px 0;
  }
  .connect-move {
    margin-top: 30px;
  }
  .connect-move h4 {
    font-size: 26px;
  }
  .connectimg-detail h5 {
    font-size: 20px;
  }
  .formsec {
    padding: 15px;
    border-radius: 15px;
  }
  .haveaque {
    padding: 20px;
    border-radius: 10px;
  }
  .contact-sourcemain {
    padding: 40px 0 0;
  }

  .trus-img {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .trus-img img {
    opacity: 1;
    filter: unset;
  }
  .trustsec {
    padding: 25px 0;
  }
  .link-detail h6 {
    margin-bottom: 8px;
  }
  .link-detail p {
    margin-bottom: 6px;
  }
  .contact-trust {
    margin-bottom: 80px;
  }

  .hero-sec {
    padding: 40px 0;
  }
  .hero-sec h1 {
    font-size: 32px;
  }
  .hero-sec p {
    font-size: 16px;
  }
  .product-text h4 {
    font-size: 20px;
  }
  .product-items h5 {
    font-size: 18px;
  }
  .product-items {
    border-radius: 14px;
    padding: 25px 21px;
  }
  .keybox-sec p {
    font-size: 14px;
  }
  .product-text {
    padding: 0;
  }
  .road-heading h5 {
    font-size: 22px;
  }
  .road-heading p {
    font-size: 14px;
  }
  .contactbtn {
    padding: 10px 0;
    justify-content: start;
  }
  .productsec {
    margin-top: 12px;
    padding: 24px;
    border-radius: 14px;
  }
  .serive-items {
    padding: 14px;
  }
  .serive-items h5 {
    font-size: 18px;
  }
  .serive-items p {
    font-size: 14px;
  }
  .heroimg img {
    width: 340px;
  }
  .keybox-img img {
    width: 420px;
  }
  .keybox-sec h6 {
    font-size: 24px;
  }
  .feature-sec {
    padding: 60px 0;
  }
  .logobox {
    padding: 8px;
  }
  .logobox img {
    width: 60px;
  }
  .product-text p {
    font-size: 14px;
  }
  .seriveicon img {
    width: 50px;
  }
  .serive-items h5 {
    margin: 12px 0 8px;
  }
  .take-items p {
    font-size: 16px;
  }
  .accordsec {
    padding: 40px 0;
  }
  .trymain {
    padding: 36px 0 235px;
  }
  .letsimg img {
    width: 370px;
  }
  .selfaccord .accordion-item {
    padding: 4px;
  }
  .selfaccord .accordion-button {
    font-size: 14px;
  }
  .selfaccord .accordion-body {
    font-size: 14px;
    padding: 10px;
  }
  .hero-sec h1 {
    font-size: 24px;
  }
  .blackbtn {
    font-size: 14px;
    padding: 7px 12px;
  }
  .yellowbtn {
    font-size: 14px;
    padding: 7px 12px;
  }
  .greenbtn {
    font-size: 14px;
    padding: 7px 12px;
  }
}

/* 576 */
@media (max-width: 767px) {
  .hero-sec {
    padding: 24px 0;
  }
  .hero-sec h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .hero-sec h4 {
    font-size: 14px;
  }
  .product-text h4 {
    font-size: 18px;
  }
  .product-items h5 {
    font-size: 16px;
    margin: 10px 0;
  }
  .product-items p {
    font-size: 14px;
  }
  .road-heading h5 {
    font-size: 18px;
  }
  .keybox-img img {
    width: 320px;
  }
  .feature-sec {
    padding: 30px 0;
  }
  .product-items {
    padding: 10px 14px;
  }
  .serive-items h5 {
    font-size: 16px;
  }
  .trust-items p {
    margin: 7px 0;
  }
  .accordsec .product-text p {
    margin-bottom: 0;
    font-size: 14px;
  }
  .letsimg img {
    width: 300px;
  }
  .trymain {
    padding: 36px 0 210px;
  }
  .link-detail p {
    font-size: 14px;
  }
  .link-detail p a {
    font-size: 14px;
  }
  .accordsec {
    padding: 30px 0;
  }
}

/* 525 */
@media (max-width: 575px) {
  .navbar-nav {
    margin-top: 5px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 4px 8px;
  }
  .navbar-toggler {
    padding: 1px 7px;
  }
  .link-detail img {
    width: 160px;
  }
  .self-nav .navbar-brand img {
    width: 160px;
  }
  .link-detail p {
    font-size: 14px;
  }
  .keybox-sec h6 {
    font-size: 18px;
  }
  .product-items h5 {
    margin-top: 0;
  }
  .product-items p {
    margin-top: 0;
  }
  .trymain {
    padding: 36px 0 180px;
  }
}

/* 480 */
@media (max-width: 524px) {
  .client-detail img {
    width: 56px;
  }
}

/* 425 */
@media (max-width: 479px) {
  .westrive-img img {
    width: 300px;
  }
  .hero-sec h1 {
    font-size: 18px;
  }
  .hero-sec p {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .heroimg img {
    width: 300px;
  }
  .productsec {
    padding: 12px;
    border-radius: 8px;
  }
  .product-items {
    border-radius: 8px;
    padding: 15px;
  }
  .mo-imgwidth {
    width: 500px;
  }
  .letsimg img {
    width: 270px;
  }
}

/* 375 */
@media (max-width: 424px) {
  .connect-move h4 {
    font-size: 20px;
  }
  .connect-move {
    margin-top: 16px;
  }
  .nudetail-sec h4 {
    font-size: 22px;
  }
  .nudetail-sec p {
    font-size: 14px;
  }
  .nudetail-main {
    margin: 14px 0;
    padding: 12px 4px;
  }
  .contact-source span i {
    font-size: 16px;
    margin-right: 2px;
    width: 38px;
    height: 38px;
  }
  .hero-sec {
    padding: 12px 0;
  }
  .hero-sec h1 {
    font-size: 16px;
  }
  .keybox-img img {
    width: 235px;
  }
  .keybox-sec img {
    width: 110px;
  }
  .product-text h4 {
    font-size: 16px;
  }
  .logobox img {
    width: 50px;
  }
  .feature-sec {
    padding: 20px 0;
  }
  .serive-items h5 {
    margin: 8px 0 6px;
  }
  .take-items h5 {
    margin: 9px 0 7px;
  }
  .take-items p {
    font-size: 12px;
  }
  .clientmain span {
    font-size: 12px;
  }
  .take-items h5 span {
    padding: 4px 10px;
    font-size: 12px;
  }
}

/* 320 */
@media (max-width: 374px) {
}

/* 280 */
@media (max-width: 319px) {
}

.dropdown:hover .dropdown-menu {
  display: block !important;
}
